<template>
  <div class="treeLoading" style="height: calc(100% - 20px);max-height: calc(100% - 20px);overflow-y: auto;overscroll-behavior: contain">
    <div style="display: flex;height: 45px;align-items: center;padding-left: 20px">
      <span>{{ '题库' }}</span>
      <Button class="empty" shape="circle" @click="empty()"><span>清空</span></Button>
    </div>
    <hr style="border-color: #d2d2d2;border-bottom: 1px;opacity: 0.2" />
    <el-tree
      :data="data"
      ref="elTree"
      show-checkbox
      style="padding: 15px 0 0 20px;"
      node-key="questionBankId"
      :default-checked-keys="checkedSelec"
      :props="defaultProps"
      @check-change="getCheckedNodes"
    />
  </div>
</template>

<script>
import questionBankApi from '@api/questionBank'
export default {
  name: 'drawByBankData',
  props: {
    IsBank: {
      type: Boolean,
      default: false
    },
    bankList: {
      type: Array,
      default: null
    },
    checkedSelec: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectData: []
    }
  },
  watch: {
    IsBank(val) {
      this.selectData = []
      if (val) {
        this.getBank()
      }
    }
    // checkedSelec: {
    //   handler() {
    //     this.getBank()
    //     // this.selectData = val
    //   },
    //   immediate: true
    // }
  },
  mounted() {
    this.getBank()
  },
  methods: {
    empty() {
      this.selectData = []
      this.getBank()
      this.$emit('checkBankNodes', this.selectData)
    },
    getBankData(data) {
      this.getBank()
    },
    getBank() {
      let loading = this.$onLoading('.treeLoading')
      questionBankApi
        .searchSimpleInfoByPermission()
        .then(res => {
          this.sortData(res.res)
        })
        .finally(() => {
          loading.close()
        })
    },
    sortData(data) {
      if (data.length > 0) {
        data.forEach(d => {
          d.label = d.name
        })
      }
      this.data = data
      if (this.checkedSelec.length > 0) {
        // 编辑修改随机出卷规则时调用此方法
        this.getSelectionBank()
      }
    },
    getSelectionBank() {
      let a = []
      this.data.forEach(d => {
        this.checkedSelec.forEach(v => {
          if (d.questionBankId === v) {
            a.push(d)
          }
        })
      })
      this.$emit('checkBankNodes', a)
    },
    getCheckedNodes() {
      this.selectData = this.$refs.elTree.getCheckedNodes()
      this.$emit('checkBankNodes', this.selectData)
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  font-size: 12px;
  width: 48px;
  height: 24px;
  color: #666;
  margin-left: 15px;
  /*position: absolute;*/
  display: flex;
  align-items: center;
  justify-content: center;
  top: 12px;
  left: 61px;
  &:hover {
    border-color: #efefef;
    outline: none;
    box-shadow: none;
    background: #efefef;
    color: #888;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
