<template>
  <div class="autoPaper">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${$route.params.bankId}` }">试卷列表</el-breadcrumb-item>
        <el-breadcrumb-item>随机出卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="examContent" style="padding: 10px">
      <div style="height: calc(100% - 50px);max-height: 780px">
        <!--          <vueScroll :ops="ops">-->
        <div style="display: flex;justify-content: space-between;height: 100%">
          <div style="width: 50%;height: 100%;padding: 15px 40px 0 40px;overflow-y: auto;">
            <!--          <vueScroll :ops="ops">-->
            <div style="font-size: 16px;color: #333;text-align: left;">设置随机出卷</div>
            <div class="examBasic">
              <p>
                <span style="color: #E66B42;margin-right: 5px;">*</span>
                <span> 随机试卷名称</span>
              </p>
              <p>
                <span style="color: #E66B42;margin-right: 12px;">*</span>
                <span>试卷分数</span>
              </p>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div>
                <el-input type="text" v-model.trim="name" size="small" :maxlength="20" style="width: 170px" placeholder="请设置试卷名称" />
              </div>
              <div style="padding-left: 20px">
                <InputNumber v-model.trim="totalScore" class="inputNum" style="width: 75px;height: 35px" :min="1" :max="999" />
              </div>
            </div>
            <div style="text-align: left;">
              <div><span style="color: #E66B42;margin-right: 5px;">*</span>选择题库</div>
              <div class="MulSelectBank">
                <div style="position: relative">
                  <Button class="secBank" style="width: 100%;text-align: left" @click="changeType(1)">
                    <span style="font-size: 14px;">选择题库</span>
                  </Button>
                  <Button class="empty" shape="circle" @click.stop="emptyByBank()"><span>清空</span></Button>
                </div>
                <!--                <Input class="secBank" @focus="IsBank = true" prefix="md-add" :placeholder="'选择题库'"/>-->
                <div :class="['checkBox', !IsAuto ? 'IsAutoFalse' : 'IsAutoTrue']" style="overflow-y: auto;overscroll-behavior: contain">
                  <!--                      <vueScroll :ops="ops">-->
                  <CheckboxGroup>
                    <div disabled style="cursor: pointer" class="check" v-for="(bank, bIndex) in bankList" :key="bIndex">
                      <div style="width: 100%">
                        <span>&nbsp;&nbsp;&nbsp;{{ bank.name }}</span>
                      </div>
                    </div>
                  </CheckboxGroup>
                  <!--                      </vueScroll>-->
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between">
              <div>
                <div style="text-align: left;">
                  <span style="color: #E66B42;margin-right: 5px;">*</span>
                  <span>岗位模型</span>
                </div>
                <div style="text-align: left;margin-top: 10px">
                  <el-select v-model="post" placeholder="请选择" @change="judgePostCode()">
                    <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </div>
              </div>
              <div v-show="codeFlag">
                <div>
                  <span style="color: #E66B42;margin-right: 5px;">*</span>
                  <span>代码题数量:</span>
                </div>
                <div style="text-align: left;margin-top: 10px">
                  <el-input-number v-model="codeQuestionCount" :min="0" label="描述文字"></el-input-number>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 30%;box-shadow:-1px 0px 6px rgba(0,0,0,0.16);">
            <drawByBankData ref="bankData" :checkedSelec="checkedSelec" :bankList="bankList" :IsBank="IsBank" @checkBankNodes="getCheckBankNodes" />
          </div>
        </div>
        <!--          </vueScroll>-->
      </div>
      <div class="footer">
        <Button @click="close">取消</Button>
        <Button type="primary" @click="createPostRandom" :loading="loading">确定</Button>
      </div>
    </div>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'
import drawByBankData from './type/drawByBankData'
import drawByKnowData from './type/drawByKnowData'
import { mapGetters, mapMutations } from 'vuex'
import paper from '../../../../api/paper'
import postApi from '@/api/post'
import newPostApi from '@/api/newPost'
export default {
  name: 'autoPaper',
  components: { vueScroll, drawByBankData, drawByKnowData },
  data() {
    return {
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      IsNum: 1,
      bankList: [],
      knowledgeList: [],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#222',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      },
      checkList: [],
      IsBank: true,
      totalQuestions: 100,
      bankHoverId: 0,
      IsKnowledge: 0,
      checkedSelec: [],
      checkedKnowSelec: [],
      loading: false,
      IsAuto: true,
      topicIsError: false,
      paperBankId: 0,
      paperId: 0,
      name: '',
      codeFlag: false,
      postData: '',
      post: '',
      codeQuestionCount: 0,
      totalScore: null
    }
  },
  mounted() {
    this.getPost()
  },
  computed: {
    ...mapGetters('question', ['getEditorData']),
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    }
  },
  methods: {
    ...mapMutations('question', ['handleSelectAll', 'setEditorData']),
    close() {
      // return
      this.$router.push({
        path: `/manage/paper/list/${this.$route.params.bankId}`
      })
      // this.$router.push({
      //   name: 'paperList',
      //   params: {
      //     paperBankId: this.$route.params.bankId
      //   }
      //   // path: `/exam/paperbank/${this.$route.params.bankId}`
      // })
    },
    //判断是否有代码题
    judgePostCode() {
      newPostApi.judgeCodeQuestion(this.post).then(res => {
        this.codeFlag = res.res
      })
    },
    //获取岗位
    getPost() {
      postApi.getPostByType(true).then(res => {
        this.postData = res.res
      })
    },
    //随机岗位模型试卷规则
    createPostRandom() {
      if (this.name.trim() === '') return this.$message({ message: '请设置试卷名称', type: 'warning' })
      else if (this.totalScore === null) {
        return this.$message.warning('请设置试卷分数')
      } else if (this.bankList.length === 0) {
        return this.$message.warning('请选择题库...')
      } else if (this.post === '') {
        return this.$message.warning('请选择岗位模型')
      } else {
        this.bankList = this.bankList.map(item => {
          return item.questionBankId
        })
        let payload = {
          codeQuestionCount: this.codeQuestionCount,
          name: this.name,
          paperBankId: this.$route.params.bankId,
          postId: this.post,
          questionBankIds: this.bankList,
          totalScore: this.totalScore
        }
        this.loading = true
        paper
          .createPostRandom(payload)
          .then(res => {
            if (res.code === 0) {
              this.$message.success('岗位模型试卷规则生成成功')
              this.loading = false
              this.close()
            } else {
              this.loading = false
              this.emptyByBank()
            }
          })
          .catch(err => {
            this.loading = false
          })
      }
    },
    emptyByBank() {
      this.bankList = []
      this.checkedSelec = []
      this.$refs.bankData.getBankData(true)
    },
    removeByBank(bank) {
      this.checkedSelec = []
      this.bankHoverId = 0
      let index = this.bankList.findIndex(item => item.questionBankId === bank.questionBankId)
      if (index !== -1) {
        this.bankList.splice(index, 1)
      }
      this.bankList.forEach(b => {
        this.checkedSelec.push(b.questionBankId)
      })
      this.$refs.bankData.getBankData(true)
    },
    getCheckBankNodes(data) {
      this.bankList = data
      if (data.length === 0) {
        this.checkedSelec = []
        return
      }
      this.checkedSelec = this.bankList.map(item => {
        return item.questionBank
      })
    },
    changeType(type) {
      if (type === 1) {
        this.IsBank = true
      } else if (type === 2) {
        this.IsBank = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wh(@a:100%, @b:100%) {
  width: @a;
  height: @b;
}
.flexStyle(@a:flex,@b:center,@c:center) {
  display: @a;
  justify-content: @b;
  align-items: @c;
}
.autoPaper {
  font-size: 14px;
  font-weight: 500;
  /*height: calc(100% - 50px);*/
  height: 100%;
  overflow: hidden;
  .bread {
    display: flex;
    justify-content: flex-start;
    margin: 15px 0;
  }
  .examContent {
    height: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
    .examBasic {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      height: 35px;
      p {
        /*width: 76px;*/
        /*height: 14px;*/
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .selectBtn {
      button {
        outline: none;
        box-shadow: none;
        color: #4579ea;
        width: 60px;
        height: 28px;
        border-radius: 4px;
        margin-right: 20px;
        border: 1px solid #4579ea;
        span {
          font-size: 14px;
        }
      }
      .focus {
        background: #4579ea;
        color: #fff;
      }
    }
    .inputNum {
      outline: none;
      box-shadow: none;
      ::v-deep .ivu-input {
        padding: 4px 26px;
      }
    }
    ::v-deep .ivu-input-number-input {
      padding: 0 24px;
    }
    ::v-deep .ivu-input:hover {
      border-color: #d2d2d2;
    }
    ::v-deep .ivu-input:focus {
      border-color: #d2d2d2;
      box-shadow: none;
    }
    .hrStyle {
      margin: 10px 0;
      width: 100%;
      border: 1px solid #eaedf1;
    }
    .MulSelectBank {
      width: 100%;
      margin: 12px 0;
      .empty {
        position: absolute;
        right: 20px;
        top: 9px;
        width: 43px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #888;
        &:hover {
          border-color: #efefef;
          outline: none;
          box-shadow: none;
          background: #efefef;
          color: #888;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .secBank {
        height: 40px;
        outline: none;
        border-color: #d2d2d2;
        box-shadow: none;
        color: #b0b0b0;
        &:hover {
          color: #4579ea;
        }
      }
      .IsAutoFalse {
        height: 200px;
      }
      .IsAutoTrue {
        height: 120px;
      }
      .checkBox {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .check {
          margin-top: 15px;
          position: relative;
          display: flex;
          /*justify-content: space-between;*/
        }
      }
      .checkBoxByKnow {
        height: 140px;
        max-height: 140px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        /*margin-top: 10px;*/
        border-radius: 3px;
        .checkByKnow {
          min-width: 100px;
          white-space: pre;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #4579ea;
          height: 30px;
          background: #ecf2fc;
          margin-right: 5px;
          margin-top: 10px;
          .icon {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .questType {
      .wh(100%, 100%);
      .flexStyle(flex, space-between, flex-start);
      flex-wrap: wrap;
      .topicS {
        .wh(33.3%, auto);
        .flexStyle(flex, flex-start, center);
        white-space: nowrap;
        min-width: 33.3%;
        max-width: 33.3%;
      }
    }
    .footer {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.03);
      button {
        &:hover {
          border-color: #d2d2d2;
          outline: none;
          box-shadow: none;
        }
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}
.iconStyle {
  width: 10px;
  height: 10px;
  color: #b0b0b0;
  &:hover {
    color: #e66b42;
  }
}
::v-deep .__view {
  height: 100%;
}
@media screen and (max-width: 1700px) {
  .checkBoxByKnow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
